import React, { useEffect } from 'react'
import { useProduct } from 'src/sdk/product/useProduct'
import { getSpecificationValue, getProductTaxonomy } from 'src/utils'

interface WishListInsiderUpdater {
  productId: string
}

export type InsiderWishlistProduct = {
  product: {
    id: string
    name: string
    taxonomy: string[]
    currency: string
    unit_price: number
    unit_sale_price: number
    url: string
    product_image_url: string
    custom: {
      subcategoria_sku: string
      material_sku: string | undefined
      pedra_sku: string | undefined
      colecao_sku: string | undefined
      departamento: string
      marca: string
      sku_id: string
      categoria_sku: string
      grupo_categoria_sku: string
      relogio_material_caixa: string | undefined
      relogio_material_pulseira: string | undefined
      publico_sku: string | undefined
    }
  }
  quantity: number
  subtotal: number
}

function WishListInsiderUpdater({ productId }: WishListInsiderUpdater) {
  const { data } = useProduct(productId)

  useEffect(() => {
    if (!data) {
      return
    }

    const specificationGroup = data.product?.specificationGroups?.find(
      (group) => group.name === 'allSpecifications'
    )

    const categories = getProductTaxonomy(data.product?.breadcrumbList)

    window.insider_object.wishlist.line_items.push({
      product: {
        id: data.product?.productId,
        name: data.product?.name,
        taxonomy: categories,
        currency: 'BRL',
        unit_price: data.product?.offers?.offers?.[0]?.listPrice.toFixed(2),
        unit_sale_price: data.product?.offers?.offers?.[0]?.price.toFixed(2),
        url: `/${data.product?.slug}`,
        product_image_url: data.product?.image?.[0]?.url,
        custom: {
          subcategoria_sku:
            data.product?.breadcrumbList?.itemListElement?.[1]?.name ?? '',
          material_sku: getSpecificationValue('Material', specificationGroup),
          pedra_sku: getSpecificationValue('Pedras', specificationGroup),
          colecao_sku: getSpecificationValue('Coleção', specificationGroup),
          departamento:
            data.product.breadcrumbList?.itemListElement?.[0]?.name ?? '',
          marca: data.product?.brand?.name,
          sku_id: data.product?.sku,
          categoria_sku: categories[1],
          grupo_categoria_sku: categories[2],
          relogio_material_caixa: getSpecificationValue(
            'Material do Vidro',
            specificationGroup
          ),
          relogio_material_pulseira: getSpecificationValue(
            'Material da Pulseira',
            specificationGroup
          ),
          publico_sku: getSpecificationValue('Sugestão', specificationGroup),
        },
      },
      quantity: 1,
      subtotal: data.product?.offers?.offers?.[0]?.price.toFixed(2),
    })
  }, [])

  return <></>
}

export default WishListInsiderUpdater
